
import { defineComponent, inject, ref, PropType, computed } from 'vue';
import router from '@/router';
import { OrderStatus } from '@/models/Enums';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { DocumentsFilter } from '@/models/Document';
import { generalStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { AwaitTaskResponse } from '@/models/Interfaces';
import { ApiResult } from '@/services/BaseApi';
import Q from 'q';
import SlotEmployeesModal from '@/components/Calendar/SlotEmployeesModal.vue';
import { CalendarSlot } from '@/models/Calendar';
import { AssignEmployeeRequest } from '@/models/Interfaces';

export default defineComponent({
    components: { SlotEmployeesModal },
    name: 'OrderActions',
    data() {
        return {};
    },
    props: {
        id: {
            type: String
        },
        orderNumber: {
            type: Number
        },
        orderStatus: {
            type: Number
        },
        orderHasTicket: {
            type: Boolean,
            default: false
        },
        employeeIds: {
            type: Array as PropType<string[]>,
            default: []
        }
    },
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const modalSlotEmployees = ref<InstanceType<typeof SlotEmployeesModal>>();
        const isActionsExpand: any = inject('isActionsExpand');
        const canAssignEmployees = ref(false);
        const perfomaInvoiceTitle = computed(() => generalStore.state.defaultProviderSettings?.defaultProviderSettingsPerfomaInvoiceTitle);
        let orderNumber: string | null = '';
        if (props.orderNumber) {
            orderNumber = props.orderNumber.toString();
        } else {
            const apiResult = await api.GetDocumentOrderNumber(props.id);
            orderNumber = apiResult.data?.orderNumber || '';
        }

        async function changeStatus(statusNumber: OrderStatus) {
            if (props.orderStatus == OrderStatus.OK && statusNumber != OrderStatus.OK) {
                let text = '';
                if (statusNumber == OrderStatus.RQ) {
                    text = t('alert.order-to-request-sure');
                }
                if (statusNumber == OrderStatus.CN) {
                    text = t('alert.order-to-cancel-sure');
                }
                const resultSwal = await swal.fire({
                    title: text,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    cancelButtonText: t('button.cancel')
                });
                if (!resultSwal.isConfirmed) return;
            }
            await Q.delay(200);
            swal.showLoading();
            const status = OrderStatus[statusNumber];
            const apiResult = await api.SetOrderStatus(props.id, status, false);
            await Q.delay(400);
            if (apiResult.data?.errorCode == 900) {
                const swalResult = await swal.fire({
                    title: t('alert.areYouSure'),
                    text: apiResult.data.error,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    cancelButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return;
                const apiResult2 = await api.SetOrderStatus(props.id, status, true);
                if (apiResult2.data?.error) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult2.data?.error
                    });
                    return;
                }
            } else if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }

            emit('changeStatus', statusNumber);
            isActionsExpand.value = false;
            swal.close();
        }
        function goAllDoc() {
            const filter = new DocumentsFilter();
            filter.orderNumber = orderNumber;
            filter.orderId = props.id;
            generalStore.commit('saveDocumentsFilter', filter);
            router.push({ name: 'documents' });
        }
        async function getLink() {
            const apiResult = await api.GetOrderLink(props.id);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data?.link) {
                swal.fire({
                    title: t('orderActions.linkPopupTitle'),
                    html: `<a href="${apiResult.data?.link}" target="_blank">${apiResult.data?.link}</a>`,
                    cancelButtonText: t('button.close'),
                    showConfirmButton: false,
                    showCancelButton: true,
                    showCloseButton: true
                });
            }
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        async function createCreditInvoice() {
            const allowCreateCreditInvoiceResult = await api.allowCreateCreditInvoice(props.id);
            if (allowCreateCreditInvoiceResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: allowCreateCreditInvoiceResult.errorMessage });
            } else {
                router.push({ name: 'newCreditInvoice', params: { orderId: props.id } });
            }
        }

        async function orderAssignEmployees() {
            const response = await api.getOrderEmployees(props.id);
            const slotItem: CalendarSlot = new CalendarSlot();
            slotItem.id = props.id;
            const assignedEmployeeIds = JSON.parse(JSON.stringify(props.employeeIds));
            const availableByOrder: string[] = response.data?.availableByOrder || [];

            modalSlotEmployees.value?.open(slotItem, '', response.data?.employees || [], availableByOrder, '', assignedEmployeeIds, false);
        }

        async function createSummary() {
            if (props.orderStatus != OrderStatus.OK) {
                swal.fire({
                    icon: 'error',
                    text: t('alert.orderSummary.errorCanBeOnlyOnOKStatus')
                });
                return;
            }

            const swalResult = await swal.fire({
                text: t('alert.orderSummary.askViewOrSendEmail'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: t('alert.orderSummary.btnView'),
                denyButtonText: t('alert.orderSummary.btnSendEmail')
            });
            if (swalResult.isDismissed) return;
            const viewNow = swalResult.isConfirmed;

            swal.showLoading();
            const apiResult2 = await api.CreateOrderSummary(props.id, viewNow);
            if (apiResult2.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: apiResult2.errorMessage
                });
                return;
            }
            if (viewNow) {
                awaitTask(
                    () => api.WaitOrderSummary(apiResult2.data!.taskId),
                    url => {
                        swal.fire({
                            icon: 'success',
                            title: t('alert.orderSummary.isReady'),
                            html: `
                        <div class="text-center my-4">
                            <a href="${url}" target="_blank">
                                <button role="button" class="popup-bookit-button my-1 px-4">${t('alert.orderSummary.btnView')}</button>
                            </a>
                        </div>`,
                            showConfirmButton: false,
                            showCloseButton: true
                        });
                        isActionsExpand.value = false;
                    }
                );
                return;
            } else {
                swal.fire({
                    icon: 'success',
                    text: t('alert.orderSummary.shedulledToSent')
                });
            }
            isActionsExpand.value = false;
        }

        const back = () => {
            emit('close');
        };

        function goToETicket() {
            if (!props.orderHasTicket) {
                swal.fire({
                    icon: 'error',
                    text: t('OrderNoTicket')
                });
                return;
            }
            router.push({ name: 'ticket', params: { id: props.id } });
        }

        function unassignEmployee(req: AssignEmployeeRequest) {
            emit('unassignEmployee', req);
        }
        function assignEmployee(req: AssignEmployeeRequest) {
            emit('assignEmployee', req);
        }

        async function checkCanAssignEmployees() {
            const response = await api.checkCanAssignEmployees(props.id);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            }
            canAssignEmployees.value = response.data ?? false;
        }

        await checkCanAssignEmployees();

        return {
            changeStatus,
            goAllDoc,
            props,
            getLink,
            createSummary,
            back,
            createCreditInvoice,
            goToETicket,
            orderAssignEmployees,
            modalSlotEmployees,
            unassignEmployee,
            assignEmployee,
            canAssignEmployees,
            perfomaInvoiceTitle
        };
    }
});
